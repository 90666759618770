import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ArrowForwardIosOutlined, ArrowBackIosOutlined, AddOutlined, RemoveOutlined } from '@material-ui/icons';
import FilledInput from '@material-ui/core/FilledInput';
import PropTypes from 'prop-types';
import StepLabel from '@material-ui/core/StepLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import './custom.css';
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import Slide from '@material-ui/core/Slide';

const useStyles = theme => ({
  root:{
    flexGrow: 1,
  },
  typography: {
    fontFamil: "Coloplast Regular"
  },
  left: {
     flexGrow: 1
  },
  floatL:{
    float:'left'
  },
  center: {
    flexGrow: 1,
  },
  right: {
    flexGrow: 1
  },
  floatR:{
    float:'right'
  },
  container: {
    padding: '0 15px',
    marginTop: '150px',
    maxWidth: '768px',
    overflowX: 'hidden'
  },
  p: {
    padding: theme.spacing(0),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '20px',
    color:'#6f6f6f',
    marginBottom: '24px'
  },
  label: {
    padding : '0px',
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    background: '#17b7cf',
    textTransform: 'capitalize',
    borderRadius: '100px',
    color: '#fff',
    display: 'inherit',
    margin: 'auto'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  h1:{
    color: '#00b0ca',
    fontSize: theme.typography.pxToRem(46),
    textAlign: 'center',
    flexGrow: 1
  },
  h2:{
    color:'#6f6f6f',
    fontSize: theme.typography.pxToRem(36),
    fontWeight: '600',
    marginBottom:'40px'
  },
  steper:{
    marginBottom:'35px'
  },
});

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
      currency: '7.15',
      wounds_less_2cm : '2',
      wounds_undermining : '12',
      wounds_non_superficial : '49',
      nursing_one_filler : '5',
      wounds_per_month : '',
      current_dressing_brand : 'current_dressing_brand',
      cost_of_dressing : '',
      current_filler_brand : 'current_filler_brand',
      estimated_cost_filler : '',
      wounds_treat_month : '',
      dressing_cost :'',
      filler_cost: '',
      activeStep: 0,
      expanded : window.innerWidth <= 768 ? '' : 'panel1',
      setExpanded : false,
      currencyWoundCareTreatment : '',
      costDressingBrand : '',
      costOfDressingAndFillerMonth : '',
      costOfFillerPerMonthAbove : '',
      hoursOfNursingTime : '',
      totalCostOfNursingTimeFiller : '',
      totalCostOfTreatmentPerMonth : '',
      totalCostOfTreatmentPerYear : '',
      biatainSiliconeProtocol : '',
      costOfBiatainDressingBrand : '',
      costOfBiatainFillerPerMonthAbove : '',
      costofBiatainDressingAndFillerPerMonth : '',
      hoursOfBiatainNursingTime : '',
      totalCostOfBiatainNursingTimeFiller : '',
      totalCostOfBiatainTreatmentPerMonth : '',
      totalCostOfBiatainTreatmentPerYear : '',
      wounds_less_2cm_error : 0,
      wounds_non_superficial_error : 0,
      wounds_undermining_error : 0,
      nursing_one_filler_error : 0,
      wounds_per_month_error : 0,
      current_dressing_brand_error : 0,
      cost_of_dressing_error : 0,
      current_filler_brand_error : 0,
      estimated_cost_filler_error : 0,
      dressing_cost_error : 0,
      filler_cost_error: 0,
      user_id : 0,
      pdf : this.props.host+'/pdf',
      estimatedNursingCostSaving:0,
      productSavingCost : 0,
      estimatedCostSavingPerYear : 0,
      isExpandedPanel1Icon : window.innerWidth <= 768 ? false : true,
      isExpandedPanel2Icon : false,
      isExpandedPanel3Icon : false,
      isExpandedPanel4Icon : false,
      tab : '/step-2',
      tabBack : '',
      slideChecked1 : false,
      slideChecked : true,
      browser : '',
    }
  }

  onResumeClick = () => {
    window.open('/assets/PM-07423_Updated Supplement to Biatain Cost Calc.pdf');
  };

  onKeyUp = name => event => {
      this.checkSteps(); 
  }

  checkSteps = () => 
  {
    const { activeStep } = this.state;
    //console.log(activeStep);
    if( activeStep == 1 )
    {
        if( this.state.wounds_per_month
          && this.state.current_dressing_brand
          && this.state.cost_of_dressing
          && this.state.current_filler_brand
          && this.state.estimated_cost_filler )
        {
          this.setState({tab : '/step-3'});
        } else {
          this.setState({tab : '/step-2'});
        }
    } else if( activeStep == 2 )
    {
        if( this.state.dressing_cost
          && this.state.filler_cost )
        {
          this.setState({tab : '/step-4'});
        } else {
          this.setState({tab : '/step-3'});
        }
    }
  }

  handleChange = name => event => {
    if( !isNaN(event.target.value) ) {
      if( event.target.value == "" ) {
          this.setState({tab : '/step-2'});
      }
      const amount = event.target.value;
      //console.log(amount);
      if( name !== 'wounds_per_month' ) {
        if (!amount || amount.match(/^\d{1,4}(\.\d{0,2})?$/)) {
          this.setState({ [name]: amount});
        }
      } else {
        this.setState({ [name]: amount});
      }
      this.checkSteps();
    }

  };

  handleOnBlur = name => event => {
      //console.log(name);
      if( event.target.value ) {
        //console.log(parseFloat(event.target.value).toFixed(2));
        this.setState({[name] : parseFloat(event.target.value).toFixed(2)});
      }
  }

  handleTextChange = name => event => {
    this.setState({ [name]: event.target.value });
    const { activeStep } = this.state;
    if( activeStep == 1 )
    {
        if( this.state.wounds_per_month
          && this.state.current_dressing_brand
          && this.state.cost_of_dressing
          && this.state.current_filler_brand
          && this.state.estimated_cost_filler )
        {
          this.setState({tab : '/step-3'});
        } else {
          this.setState({tab : '/step-2'});
        }
    } 
  };

  // Update the active state according to the next button press
  handleNext = (e) => {
    const { activeStep } = this.state;
    if (activeStep == 0) {
        
        if( this.state.wounds_less_2cm == "" )
        {
          this.setState({
            wounds_non_superficial_error : 0,
            wounds_undermining_error : 0,
            nursing_one_filler_error : 0
          });
          document.getElementById("wounds_less_2cm").focus()
          this.setState({wounds_less_2cm_error : 1});
          return false;
        } else if( this.state.wounds_undermining == "" )
        {
          this.setState({
            wounds_less_2cm_error : 0,
            wounds_undermining_error : 0,
            nursing_one_filler_error : 0
          });
          document.getElementById("wounds_undermining").focus()
          this.setState({wounds_undermining_error : 1});
          return false;
        } else if( this.state.wounds_non_superficial == "" )
        {
          this.setState({
            wounds_less_2cm_error : 0,
            wounds_undermining_error : 0,
            nursing_one_filler_error : 0
          });
          document.getElementById("wounds_non_superficial").focus()
          this.setState({wounds_non_superficial_error : 1});
          return false;
        } else if( this.state.nursing_one_filler == "" )
        {
          this.setState({
            wounds_less_2cm_error : 0,
            wounds_non_superficial_error : 0,
            wounds_undermining_error : 0,
          });
          document.getElementById("nursing_one_filler").focus()
          this.setState({nursing_one_filler_error : 1});
          return false;
        } else {
          window.scrollTo(0, 0);
          this.setState({
            wounds_less_2cm_error : 0,
            wounds_non_superficial_error : 0,
            wounds_undermining_error : 0,
            nursing_one_filler_error : 0,
            activeStep: activeStep + 1
          });

                  if( this.state.wounds_per_month
              && this.state.current_dressing_brand
              && this.state.cost_of_dressing
              && this.state.current_filler_brand
              && this.state.estimated_cost_filler )
            {
              this.setState({tab : '/step-3'});
            } else {
              this.setState({tab : '/step-2'});
            }
        }
    } else if (activeStep == 1) {

        if( this.state.wounds_per_month == "" )
        {
          this.setState({
            current_dressing_brand_error : 0,
            cost_of_dressing_error : 0,
            current_filler_brand_error : 0,
            estimated_cost_filler_error : 0
          });
          document.getElementById("wounds_per_month").focus()
          this.setState({wounds_per_month_error : 1});
          return false;
        } else if( this.state.current_dressing_brand == "" )
        {
          this.setState({
            wounds_per_month_error : 0,
            cost_of_dressing_error : 0,
            current_filler_brand_error : 0,
            estimated_cost_filler_error : 0
          });
          document.getElementById("current_dressing_brand").focus()
          this.setState({current_dressing_brand_error : 1});
          return false;
        } else if( this.state.cost_of_dressing == "" )
        {
          this.setState({
            wounds_per_month_error : 0,
            current_dressing_brand_error : 0,
            current_filler_brand_error : 0,
            estimated_cost_filler_error : 0
          });
          document.getElementById("cost_of_dressing").focus()
          this.setState({cost_of_dressing_error : 1});
          return false;
        } else if( this.state.current_filler_brand == "" )
        {
          this.setState({
            wounds_per_month_error : 0,
            current_dressing_brand_error : 0,
            cost_of_dressing_error : 0,
            estimated_cost_filler_error : 0
          });
          document.getElementById("current_filler_brand").focus()
          this.setState({current_filler_brand_error : 1});
          return false;
        } else if( this.state.estimated_cost_filler == "" )
        {
          this.setState({
            wounds_per_month_error : 0,
            current_dressing_brand_error : 0,
            cost_of_dressing_error : 0,
            current_filler_brand_error : 0,
          });
          document.getElementById("estimated_cost_filler").focus()
          this.setState({estimated_cost_filler_error : 1});
          return false;
        } else {
          window.scrollTo(0, 0)
          this.setState({
            wounds_per_month_error : 0,
            current_dressing_brand_error : 0,
            cost_of_dressing_error : 0,
            current_filler_brand_error : 0,
            estimated_cost_filler_error : 0,
            tabBack : '/step-2',
            activeStep: activeStep + 1
          });
           if( this.state.dressing_cost
            && this.state.filler_cost )
          {
            this.setState({tab : '/step-4'});
          } else {
            this.setState({tab : '/step-3'});
          }
        }
    } else if( activeStep === 2 )
    {
      if( this.state.dressing_cost
            && this.state.filler_cost )
          {
            this.setState({tab : '/step-4'});
          } else {
            this.setState({tab : '/step-3'});
          } 
      if( this.state.dressing_cost == "" )
      {
        this.setState({
          filler_cost_error : 0
        });
        document.getElementById("dressing_cost").focus()
        this.setState({dressing_cost_error : 1});
        return false;
      } else if( this.state.filler_cost == "" )
      {
        this.setState({
          dressing_cost_error : 0,
        });
        document.getElementById("filler_cost").focus()
        this.setState({filler_cost_error : 1});
        return false;
      } else {
        window.scrollTo(0, 0);
        fetch(this.props.host+'/totalCalculation', {
          method: "POST",
          body: JSON.stringify({
            'wounds_per_month' : this.state.wounds_per_month,
            'currency' : this.state.currency,
            'wounds_less_2cm' : this.state.wounds_less_2cm,
            'wounds_non_superficial' : this.state.wounds_non_superficial,
            'nursing_one_filler' : this.state.nursing_one_filler,
            'current_dressing_brand' : this.state.current_dressing_brand,
            'cost_of_dressing' : this.state.cost_of_dressing,
            'current_filler_brand' : this.state.current_filler_brand,
            'estimated_cost_filler' : this.state.estimated_cost_filler,
            'dressing_cost' : this.state.dressing_cost,
            'filler_cost' : this.state.filler_cost,
            'wounds_undermining' : this.state.wounds_undermining,
            'user_id' : this.state.user_id
          }),
          headers: {
            'Content-type': 'application/json',

          },
        })
        .then(response => response.json())
        .then(responseJson => {
            this.setState({
              currencyWoundCareTreatment : responseJson.wound_care.currencyWoundCareTreatment,
              costDressingBrand : responseJson.wound_care.costDressingBrand,
              costOfDressingAndFillerMonth : responseJson.wound_care.costOfDressingAndFillerMonth,
              costOfFillerPerMonthAbove : responseJson.wound_care.costOfFillerPerMonthAbove,
              hoursOfNursingTime : responseJson.wound_care.hoursOfNursingTime,
              totalCostOfNursingTimeFiller : responseJson.wound_care.totalCostOfNursingTimeFiller,
              totalCostOfTreatmentPerMonth : responseJson.wound_care.totalCostOfTreatmentPerMonth,
              totalCostOfTreatmentPerYear : responseJson.wound_care.totalCostOfTreatmentPerYear,
              biatainSiliconeProtocol : responseJson.biatain_silicone.biatainSiliconeProtocol,
              costOfBiatainDressingBrand : responseJson.biatain_silicone.costOfBiatainDressingBrand,
              costOfBiatainFillerPerMonthAbove : responseJson.biatain_silicone.costOfBiatainFillerPerMonthAbove,
              costofBiatainDressingAndFillerPerMonth : responseJson.biatain_silicone.costofBiatainDressingAndFillerPerMonth,
              hoursOfBiatainNursingTime : responseJson.biatain_silicone.hoursOfBiatainNursingTime,
              totalCostOfBiatainNursingTimeFiller : responseJson.biatain_silicone.totalCostOfBiatainNursingTimeFiller,
              totalCostOfBiatainTreatmentPerMonth : responseJson.biatain_silicone.totalCostOfBiatainTreatmentPerMonth,
              totalCostOfBiatainTreatmentPerYear : responseJson.biatain_silicone.totalCostOfBiatainTreatmentPerYear,
              estimatedNursingCostSaving : responseJson.estimatedNursingCostSaving,
              productSavingCost : responseJson.productSavingCost,
              estimatedCostSavingPerYear : responseJson.estimatedCostSavingPerYear,
              user_id : responseJson.user.user_id
            });
        }).catch((err) => {
            console.log(err);
        });
        this.setState({
          activeStep: activeStep + 1,
          tabBack : '/step-3',
          dressing_cost_error : 0,
          filler_cost_error : 0
        });
      }
    }
  };

  // Similar for back and reset buttons
  handleBack = () => {
    const { activeStep } = this.state;
    window.scrollTo(0, 0);
    if( activeStep == 4 )
    {
      this.setState({tabBack: '/step-3'});
      this.setState({tab: '/step-4'});
    } else if( activeStep == 3 )
    {
      this.setState({tabBack:'/step-2'});
      this.setState({tab: '/step-4'});
    } else if( activeStep == 2 )
    {
      this.setState({tabBack:'/'});
      this.setState({tab: '/step-3'});
    } else {
      this.setState({tab : '/step-2'});
    }
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleReset = () => {
    window.scrollTo(0, 0);
    this.setState({
      activeStep: 0,
      tab : '/step-2',
      tabBack : '/'
    });
  };W

  getNumberOfSteps() {
    return 4;
  }

  componentDidMount() {
    if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      this.setState({
        browser : 'IE'
      }); 
    } else {
      this.setState({
        browser : 'normal'
      }); 
    }  
    
    if (window.performance) {
      if (performance.navigation.type == 1) {
          if (window.location.href.indexOf("step-2") > -1 || window.location.href.indexOf("step-3") > -1 || window.location.href.indexOf("step-4") > -1) {
              var url = window.location.href;
              url = url.replace('/step-2',"");
              url = url.replace('/step-3',"");
              url = url.replace('/step-4',"");
              window.location.replace(url);
          }
      }
    }
    const {activeStep} = this.state;
    this._isMounted = true;
    window.onpopstate = ()=> {
      if(this._isMounted) {
        const hash = window.location.href;
        //console.log(hash);
        //console.log(activeStep);
        if(hash.indexOf('step-2')>-1 && activeStep!==1) {
         // console.log("activeStep=1");
          this.setState({
            activeStep: 1,
            tabBack : '/'
          });
          this.checkSteps();
        }
        else if(hash.indexOf('step-3')>-1 && activeStep!==2) {
          //console.log("activeStep=2");
          this.setState({
            activeStep: 2,
            tabBack : '/step-2'
          })
          this.checkSteps();
        }
        else if(hash.indexOf('step-4')>-1 && activeStep!==3) {
          //console.log("activeStep=3");
          this.setState({
            activeStep: 3,
            tabBack : '/step-3'
          })
        }
        else {
         // console.log("activeStep=0");
          this.setState({
            activeStep: 0,
            tab : '/step-2',
            tabBack : '/'
          });

        }
      }
    }

  }

  render() {
    const { classes } = this.props;
    const {activeStep} = this.state;

    const currencySelection = [
      {
        value: '7.15',
        label: '$7.15 USD',
      },
      {
        value: '6.25',
        label: '$6.25 CAD',
      },
    ];

    const handleAccordianChange = panel => (event, isExpanded) => {
        
        if(isExpanded == true){
          if( panel == 'panel1' ) {
            this.setState({isExpandedPanel1Icon : true});
            this.setState({isExpandedPanel2Icon : false});
            this.setState({isExpandedPanel3Icon : false});
            this.setState({isExpandedPanel4Icon : false});
          } else if( panel == 'panel2' ) {
            this.setState({isExpandedPanel2Icon : true});
            this.setState({isExpandedPanel3Icon : false});
            this.setState({isExpandedPanel4Icon : false});
          } else if( panel == 'panel3' ) {
            this.setState({isExpandedPanel3Icon : true});
            this.setState({isExpandedPanel2Icon : false});
            this.setState({isExpandedPanel4Icon : false});
          } else if( panel == 'panel4' ) {
            this.setState({isExpandedPanel4Icon : true});
            this.setState({isExpandedPanel2Icon : false});
            this.setState({isExpandedPanel3Icon : false});
          }
          this.setState({expanded : panel});
        }else{
          if( panel == 'panel1' ) {
            this.setState({isExpandedPanel1Icon : false});
            this.setState({expanded : false});
          } else if( panel == 'panel2' ) {
            this.setState({isExpandedPanel2Icon : false});
            this.setState({expanded : false});
          } else if( panel == 'panel3' ) {
            this.setState({isExpandedPanel3Icon : false});
            this.setState({expanded : false});
          } else if( panel == 'panel4' ) {
            this.setState({isExpandedPanel4Icon : false});
            this.setState({expanded : false});
          }
         
        }
    };

    const getStepContent = (step) => {
      switch (step) {
        case 0:
          return (
            <div className="fieldsContent">
              <ExpansionPanel className="stepmain-exp showExp-desktop" expanded={this.state.expanded == 'panel1'} onChange={handleAccordianChange('panel1')}>
                <ExpansionPanelSummary
                  expandIcon={this.state.isExpandedPanel1Icon ? "-" : "+"}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography align="center" variant="h2" className={classes.h2}>Cost Calculator Assumptions</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>

                  <Typography component="p" align="center" className={classes.p}>
                  Coloplast sought to better understand the most prevalent chronic wound types in patients and performed a literature review of current evidence. Since <strong>Biatain&reg; Silicone with 3DFit technology™</strong> eliminates the need for a filler on wounds that are 2cm in depth or less when the wound is not undermined, it was then necessary to describe these wound characteristics clinically to establish context around treatment options.
                  </Typography>
                  <Typography component="p" align="center" className={classes.p}>
                  A large-scale study (154,644 patients with 312,744 wounds) was performed by Wilcox¹ et al (2013) in the U.S., which described prevalence of wound types and wound bed depth. Diabetic foot ulcers (DFU), pressure injuries (PI), and venous leg ulcer (VLU) wounds were found to be among the most prevalent. For more information on the background of these studies, the findings, and calculations please refer to the <strong>Biatain&reg; Silicone 3DFit Cost Calculator supplement </strong>
                  </Typography>
                  <Button align="center" onClick={this.onResumeClick} className={classes.button}>Calculator Assumptions</Button>

                </ExpansionPanelDetails>
              </ExpansionPanel>

              <div className="fieldsContainer">
              <InputLabel htmlFor="wounds_less_2cm" className={classes.label}>Percentage of wounds more than 2cm in depth</InputLabel>
              <FilledInput
                className={classes.label}
                variant="outlined"
                id="wounds_less_2cm"
                value={this.state.wounds_less_2cm + "%"}
                readOnly={true}
              />
              { this.state.wounds_less_2cm_error > 0  &&
                <FormHelperText className="error_text" id="wounds_less_2cm-text">Percentage of Wounds not be empty or text</FormHelperText>
              }
              <InputLabel htmlFor="wounds_undermining" className={classes.label}>Percentage of wounds that have undermining</InputLabel>
              <FilledInput
                className={classes.label}
                variant="outlined"
                id="wounds_undermining"
                value={this.state.wounds_undermining + "%"}
                readOnly={true}
              />
              { this.state.wounds_undermining_error > 0  &&
                <FormHelperText className="error_text" id="wounds_undermining-text">Percentage of Wounds undermining cannot be empty or text</FormHelperText>
              }
              <InputLabel htmlFor="wounds_non_superficial" className={classes.label}>Percentage of superficial wounds with no depth</InputLabel>
              <FilledInput
                className={classes.label}
                variant="outlined"
                id="wounds_non_superficial"
                value={this.state.wounds_non_superficial + "%"}
                readOnly={true}
              />
              { this.state.wounds_non_superficial_error > 0  &&
                <FormHelperText className="error_text" id="wounds_non_superficial-text">Percentage of non-superficial cannot be empty or text</FormHelperText>
              }
              <InputLabel htmlFor="nursing_one_filler" className={classes.label}>Nursing time for one filler application
              <small> (minutes)</small></InputLabel>
              <FilledInput
                className={classes.label}
                variant="outlined"
                id="nursing_one_filler"
                value={this.state.nursing_one_filler}
                readOnly={true}
              />
              { this.state.nursing_one_filler_error > 0  &&
                <FormHelperText className="error_text" id="nursing_one_filler-text">Nursing Time cannot be empty or text</FormHelperText>
              }
              <InputLabel className={classes.label}>Cost of nursing time for one filler application
              <small> (Based on hourly wage of $85.50 in US and 75.00 in CA)</small></InputLabel>
              <TextField
                select
                className={[classes.label, "selectDD"].join(" ")}
                variant="outlined"
                id="currency_nursing"
                value={this.state.currency}
                onChange={this.handleChange('currency')}
              >

              {currencySelection.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </div>
            </div>
            );
        case 1:
          return (
            <div className="fieldsContent">
              <Typography align="center" variant="h2">
                  Current Wound Care Treatment
              </Typography>
              <div className="fieldsContainer">
              <InputLabel htmlFor="wounds_per_month" className={classes.label}>Number of wounds treated per month</InputLabel>
              <TextField
                className={classes.label}
                variant="outlined"
                id="wounds_per_month"
                value={this.state.wounds_per_month}
                onChange={this.handleChange('wounds_per_month')}
                onKeyUp={this.onKeyUp('wounds_per_month')}
                type="number"
                onInput = {(e) =>{
                  //e.target.value = Math.max(0, parseFloat(e.target.value) ).toString().slice(0,4)
                }}
              />
              { this.state.wounds_per_month_error > 0  &&
                <FormHelperText className="error_text" id="wounds_per_month-text">Number of wounds cannot be empty or text</FormHelperText>
              }
              <InputLabel htmlFor="cost_of_dressing" className={classes.label}>Current estimated cost per dressing</InputLabel>
              <TextField
                className={classes.label}
                variant="outlined"
                id="cost_of_dressing"
                value={this.state.cost_of_dressing}
                onChange={this.handleChange('cost_of_dressing')}
                onBlur={this.handleOnBlur('cost_of_dressing')}
                onKeyUp={this.onKeyUp('cost_of_dressing')}
                type="number"
                step={0.01}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onInput = {(e) =>{


                  //e.target.value = Math.max(0, parseFloat(e.target.value) ).toString().slice(0,6)
                }}
              />
              { this.state.cost_of_dressing_error > 0  &&
                <FormHelperText className="error_text" id="cost_of_dressing-text">Cost of Dressing cannot be empty or text</FormHelperText>
              }
              <InputLabel htmlFor="estimated_cost_filler" className={classes.label}>Current estimated cost per filler</InputLabel>
              <TextField
                className={classes.label}
                variant="outlined"
                id="estimated_cost_filler"
                value={this.state.estimated_cost_filler}
                onChange={this.handleChange('estimated_cost_filler')}
                onBlur={this.handleOnBlur('estimated_cost_filler')}
                onKeyUp={this.onKeyUp('estimated_cost_filler')}
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              { this.state.estimated_cost_filler_error > 0  &&
                <FormHelperText className="error_text" id="estimated_cost_filler-text">Cost of Filler cannot be empty or text</FormHelperText>
              }
              </div>
            </div>
            );
        case 2:
          return (
            <div className="fieldsContent">
              <Typography align="center" variant="h2">
                 Biatain® Silicone Foam Dressing Plan
              </Typography>
              <div className="fieldsContainer step3-css">
                <div className="b-3dfitTech">
                    <p>Biatain® Silicone with 3DFit Technology™ is designed to fill the gap and reduce exudate pooling</p>
                </div>
                <div className="fields-s3">
                  <InputLabel className={classes.label}>Number of wounds treated per month</InputLabel>
                  <FilledInput
                    className={classes.label}
                    variant="outlined"
                    value={this.state.wounds_per_month}
                    readOnly={true}
                    margin="dense"
                  />
                  <InputLabel htmlFor="dressing_cost" className={classes.label}><strong>Biatain® Silicone</strong> estimated cost per dressing</InputLabel>
                  <TextField
                    className={classes.label}
                    variant="outlined"
                    id="dressing_cost"
                    value={this.state.dressing_cost}
                    onChange={this.handleChange('dressing_cost')}
                    onBlur={this.handleOnBlur('dressing_cost')}
                    onKeyUp={this.onKeyUp('dressing_cost')}
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  { this.state.dressing_cost_error > 0  &&
                    <FormHelperText className="error_text" id="dressing_cost-text">Dressing Cost cannot be empty or text</FormHelperText>
                  }
                  <InputLabel htmlFor="filler_cost" className={classes.label}><strong>Biatain® Alginate</strong> estimated cost per filler</InputLabel>
                  <TextField
                    className={classes.label}
                    variant="outlined"
                    id="filler_cost"
                    value={this.state.filler_cost}
                    type="number"
                    onChange={this.handleChange('filler_cost')}
                    onBlur={this.handleOnBlur('filler_cost')}
                    onKeyUp={this.onKeyUp('filler_cost')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  { this.state.filler_cost_error > 0  &&
                    <FormHelperText className="error_text" id="filler_cost-text">Filler Cost cannot be empty or text</FormHelperText>
                  }
                </div>
              </div>
            </div>
            );
        case 3:
          return (
            <div className="fieldsContent exp-blue finalStepmain">
              <Typography align="center" variant="h2">Cost Savings Summary</Typography>
              <div className="costSummery">
                  <List className={classes.root}>
                        <ListItem>
                          <ListItemText primary={'Current Wound Care Treatment Cost'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.totalCostOfTreatmentPerYear} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={<div><strong>Biatain® Silicone</strong> Foam Dressing Plan Cost</div>} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.totalCostOfBiatainTreatmentPerYear} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Product Cost Savings'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.productSavingCost} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Estimated Yearly Nursing Cost Savings'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.estimatedNursingCostSaving} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Estimated Yearly Cost Savings'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.estimatedCostSavingPerYear} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                  </List>
              </div>
              <Typography align="center" component="small" className="summerySmalltxt">*This cost savings represents one dressing change per wound. </Typography>
              
              <ExpansionPanel className="step1-exp" expanded={this.state.expanded === 'panel2'} onChange={handleAccordianChange('panel2')}>
                <ExpansionPanelSummary
                  expandIcon={this.state.isExpandedPanel2Icon ? "-" : "+"}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography component="h2" className={classes.heading}>Cost Calculator Assumptions</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="fieldDetails">
                    <div className="fieldsContainer">
                      <InputLabel className={classes.label}>Percentage of wounds less than 2cm deep</InputLabel>
                      <FilledInput
                        className={classes.label}
                        variant="outlined"
                        value={this.state.wounds_less_2cm + "%"}
                        readOnly={true}
                        margin="dense"
                      />
                      <InputLabel className={classes.label}>Percentage of wounds that are undermining</InputLabel>
                      <FilledInput
                        className={classes.label}
                        variant="outlined"
                        value={this.state.wounds_undermining}
                        readOnly={true}
                        margin="dense"
                      />
                      <InputLabel className={classes.label}>Percentage of non-superficial wounds with
                      depth less than 2cm (and not undermined)</InputLabel>
                      <FilledInput
                        className={classes.label}
                        variant="outlined"
                        value={this.state.wounds_non_superficial + "%"}
                        readOnly={true}
                        margin="dense"
                      />
                      <InputLabel className={classes.label}>Nursing time for one filler application
                      (minutes)</InputLabel>
                      <FilledInput
                        className={classes.label}
                        variant="outlined"
                        value={this.state.nursing_one_filler}
                        readOnly={true}
                        margin="dense"
                      />
                      <InputLabel className={classes.label}>Cost of nursing time for one filler application
                      (Based on hourly wage of $85.50 in US and 75.00 in CA)</InputLabel>
                      <FilledInput
                        className={classes.label}
                        variant="outlined"
                        value={this.state.currency == "7.15" ? "$" + this.state.currency + " USD" : "$" + this.state.currency + " CAD"}
                        readOnly={true}
                        margin="dense"
                      />
                    </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel className="step1-exp" expanded={this.state.expanded === 'panel3'} onChange={handleAccordianChange('panel3')}>
                <ExpansionPanelSummary
                  expandIcon={this.state.isExpandedPanel3Icon ? "-" : "+"}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography component="h2" className={classes.heading}>Current Wound Care Treatment</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="fieldDetails">
                <div className="fieldsContainer">
                    <Typography component="h3">Cost Recap & Calculations</Typography>
                    <InputLabel className={classes.label}>Number of wounds treated per month</InputLabel>
                    <FilledInput
                      className={classes.label}
                      variant="outlined"
                      value={this.state.wounds_per_month}
                      readOnly={true}
                      margin="dense"
                    />
                    <InputLabel className={classes.label}>Estimated cost for 4x4in (10x10cm) dressing</InputLabel>
                    <FilledInput
                      className={classes.label}
                      variant="outlined"
                      value={"$" + this.state.cost_of_dressing}
                      readOnly={true}
                      margin="dense"
                    />
                    <InputLabel className={classes.label}>Estimated cost for 4x4in (10x10cm) filler</InputLabel>
                    <FilledInput
                      className={classes.label}
                      variant="outlined"
                      value={"$" + this.state.estimated_cost_filler}
                      readOnly={true}
                      margin="dense"
                    />
                    </div>
                    <List className="detailCalculations">
                        <Typography component="h4">Detailed Calculations</Typography>
                        <ListItem>
                          <ListItemText primary={'Current Wound Care Treatment Protocol'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.currencyWoundCareTreatment} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Cost of 4x4 on {this.state.wounds_per_month} wounds</ListItemText>
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.costDressingBrand} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Cost of 'Est. Fillers per Month' from above</ListItemText>
                          <ListItemSecondaryAction>
                             <NumberFormat value={this.state.costOfFillerPerMonthAbove} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Cost of Dressings and Fillers per Month'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.costOfDressingAndFillerMonth} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Hours of Nursing time for filler application (Est. 5 mins per application)'} />
                          <ListItemSecondaryAction>{this.state.hoursOfNursingTime}</ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Total Cost of excess Nursing Time to apply filler'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.totalCostOfNursingTimeFiller} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Total Cost of Treatment per Month'} />
                          <ListItemSecondaryAction>
                              <NumberFormat value={this.state.totalCostOfTreatmentPerMonth} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Total Cost of Treatment per Year (Current)'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.totalCostOfTreatmentPerYear} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel className="step1-exp" expanded={this.state.expanded === 'panel4'} onChange={handleAccordianChange('panel4')}>
                <ExpansionPanelSummary
                  expandIcon={this.state.isExpandedPanel4Icon ? "-" : "+"}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography component="h2" className={classes.heading}>Biatain® Silicone Foam Dressing Plan</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="fieldDetails">
                  <div className="fieldsContainer">
                    <Typography component="h3">Cost Recap & Calculations</Typography>
                    <InputLabel className={classes.label}>Number of wounds treated per month</InputLabel>
                    <FilledInput
                      className={classes.label}
                      variant="outlined"
                      value={this.state.wounds_per_month}
                      readOnly={true}
                      margin="dense"
                    />
                    <InputLabel className={classes.label}><strong>Biatain® Silicone</strong> dressing cost</InputLabel>
                    <FilledInput
                      className={classes.label}
                      variant="outlined"
                      value={"$"+ this.state.dressing_cost}
                      readOnly={true}
                      margin="dense"
                    />
                    <InputLabel className={classes.label}><strong>Biatain® Silicone</strong> filler cost</InputLabel>
                    <FilledInput
                      className={classes.label}
                      variant="outlined"
                      value={"$"+ this.state.filler_cost}
                      readOnly={true}
                      margin="dense"
                    />
                    </div>
                    <List className="detailCalculations">
                        <Typography component="h4">Detailed Calculations</Typography>
                        <ListItem>
                          <ListItemText primary={'Biatain® Silicone 3DFit™ Protocol'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.biatainSiliconeProtocol} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText>Cost of Biatain® Silicone Ag 4x4 on {this.state.wounds_per_month} wounds</ListItemText>
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.costOfBiatainDressingBrand} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Cost of Biatain® Alginate on 'Est. Fillers per Month' from above" />
                          <ListItemSecondaryAction>
                              <NumberFormat value={this.state.costOfBiatainFillerPerMonthAbove} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Cost of Dressings and Fillers per Month'} />
                          <ListItemSecondaryAction>
                            <NumberFormat value={this.state.costofBiatainDressingAndFillerPerMonth} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Hours of Nursing time for filler application (Est. 5 mins per application)'} />
                          <ListItemSecondaryAction>{this.state.hoursOfBiatainNursingTime}</ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Total Cost of excess Nursing Time to apply filler'} />
                          <ListItemSecondaryAction>
                             <NumberFormat value={this.state.totalCostOfBiatainNursingTimeFiller} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Total Cost of Treatment per Month'} />
                          <ListItemSecondaryAction>
                              <NumberFormat value={this.state.totalCostOfBiatainTreatmentPerMonth} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={'Total Cost of Treatment per Year (3DFit™)'} />
                          <ListItemSecondaryAction>
                              <NumberFormat value={this.state.totalCostOfBiatainTreatmentPerYear} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <div className="bottomButton pdf-download">
                <form target="_blank" method="post" action={this.state.pdf} >
                  <Input type="hidden" name="user_id" value={this.state.user_id} /> 
                  <Button className={classes.button} type="submit">Download PDF <ArrowForwardIosOutlined className={classes.icon} /></Button>
                  <Input type="hidden" name="detect" value={this.state.browser} /> 
                </form>
              </div>
            </div>
            );
        default:
          return 'Unknown step';
      }
    }
   
    return (
      <BrowserRouter>
        <div className={classes.root}>
          <AppBar position="fixed" color="default">
            <Toolbar>
              <Typography variant="h6">
                <img src={'/assets/coloplast-logo@2x.png'} alt="Coloplast"  height="40" /> 
              </Typography>
              <Typography variant="h1" className={classes.h1}>
                <Link to={'/'} onClick={this.handleReset} className="title">
                    <strong >Biatain</strong>® <strong>Silicone Cost Calculator</strong>
                </Link>
              </Typography>
              <Typography variant="h6"> 
                <img src={'/assets/3dfit-logo@2x.png'} alt="3D Fit"  height="50" /> 
              </Typography>
            </Toolbar>
              <Typography variant="h3" className="mobileText">
                <Link to={'/'} onClick={this.handleReset} className="title">
                  <strong>Biatain</strong>® <strong>Silicone Cost Calculator</strong>
                </Link>
              </Typography>
          </AppBar>
          <Container className={[classes.container, "container-spacing"].join(" ")}>
            <div>
              {activeStep === this.getNumberOfSteps() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&quot;re finished
                  </Typography>
                  <Button onClick={this.handleReset} className={classes.button}>
                    Reset
                  </Button>
                </div>
              ) : (
              <Route
              path="/"
              render={({ location }) => (
                <div>
                  <Stepper className={["stepCounts", "step_"+activeStep].join(" ")} activeStep={activeStep}>
                      <Step key={0} primary="1" className="stepKey stepOne">
                        <StepLabel className="stepLabel"></StepLabel>
                      </Step> 
                      <Step key={1} className="stepKey stepTwo">
                        <StepLabel className="stepLabel"></StepLabel>
                      </Step>
                      <Step key={2} className="stepKey stepThree">
                        <StepLabel className="stepLabel"></StepLabel> 
                      </Step>
                      <Step key={3} className="stepKey stepFour">
                        <StepLabel className="stepLabel"></StepLabel>
                      </Step>
                    </Stepper>
                  {
                    // Populate the content pane based on the active step
                    <div>
                    
                     <AnimatedSwitch
      atEnter={{ offset: 100 }}
      atLeave={{ }}
      atActive={{ offset: 0 }}
      mapStyles={(styles) => ({
        transform: `translateX(${styles.offset}%)`,
      })}
    >
                      <Route path="/step-2" render={() => getStepContent(1)} />
                      <Route path="/step-3" render={() => getStepContent(2)} />
                      <Route path="/step-4" render={() => getStepContent(3)} />
                      <Route path="/" render={() => getStepContent(0)} />
                    </AnimatedSwitch>
                    
                    </div>
                  }
                  <div className="bottomButton">
                    <Link to={this.state.tabBack}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={[classes.button, classes.floatL, activeStep !== 0 ? 'show' : 'hide'].join(" ")}
                      >
                        <ArrowBackIosOutlined className={classes.icon} />
                        Back
                      </Button>
                    </Link>
                    <Link to={this.state.tab}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={this.handleNext}
                        className={[classes.button, classes.floatR, activeStep === this.getNumberOfSteps() - 1 ? 'hide' : 'show'].join(" ")}
                      >
                          {activeStep === this.getNumberOfSteps() - 1 ? 'Finish' : 'Next'}
                          <ArrowForwardIosOutlined className={classes.icon} />             
                      </Button>
                    </Link>
                  </div>
                </div>
                )}
                />
              )}
            </div>
          </Container>
          <footer>Coloplast Corporation - 1601 West River Road N - Minneapolis MN 55411 USA TEl:1-800-533-0464</footer>
        </div>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(App);